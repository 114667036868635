@import "index", "highlight";

.archive {
  font-size: 1.1em; }

.archive time {
  display: inline-block;
  min-width: 10ch;
  margin: 0 .2em; }





.title {
  font-size: 1.3em; }





.hidden {
  display: none; }





.icons svg {
  height: 1em;
  width: 1em;
  fill: currentColor;
  transition: .2s color;
  vertical-align: middle;
  margin-bottom: .15em; }





.more {
  margin: 2em 0 1em; }

.more a {
  border-radius: 2px;
  border: 1.5px solid $link-color;
  padding: .4em .8em;
  transition: .2s; }

.more a:hover {
  color: #fff;
  background: $link-color;
  text-decoration: inherit; }





.related div {
  flex: 1 50%;
  min-width: 15em; }

.related span {
  display: block;
  color: reduce(100); }

.related a {
  font-size: 1.2em;
  font-weight: bold; }

.next {
  text-align: right; }






